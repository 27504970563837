import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { AuroraCommunityHistory } from 'components/AuroraCommunityHistory'
import { AuroraCommunityGeo } from 'components/AuroraCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { AuroraCommunityHero } from 'components/AuroraCommunityHero'
import { AuroraCommunityInformation } from 'components/AuroraCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchAurora'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentAurora'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const AuroraPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv className="cityPage">
            <PropertiesComponent defaultCity="Aurora" />
      
        {/* <CommunitySearch /> */}
        <Contact />
        </FlexDiv>
    </EllicotLayout>
  )
}

export default AuroraPage
